.header__ico-menu{
    display: block;
    width: 30px;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 10px;
}

.header__nav-menu{
    margin-top: 30px;
}

.header__nav-logo{
}

.header__nav-container{
    position: relative;
}

@media only screen and (min-width: 992px) {
    .header__ico-menu{
        display: none;
    }
}

.list-item {
    display: flex;
    align-items: center;
  }
  
.icon {
    width: 24px; 
    height: 24px;
    margin-left: 2vh;
}

.children-link {
    text-decoration: none;
    color: white !important;
}