.button-iniciar-reclamo {
  align-content: center;
  float: right;
  cursor: pointer;
  width: 35%;
}

.fondo-circular {
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icono-svg {
    fill: white;
    height: 20px;
  }
  .icono-svg-black {
    fill: white;
    height: 20px;
    margin-top: 10px;
    margin-left: 35px
  }
  