.textDecorationBlack {
    text-decoration: 'none' !important;
    color: #000000 !important;
}

.textDecorationWhite {
    text-decoration: 'none' !important;
    color: 'white' !important;
}

.truncate-text-08 {
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-text-12 {
    font-size: 1.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate-text-7 {
    text-align: right;
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
}


.padding-1 {
    padding: 1vw;
}

.padding-05 {
    padding: 0.4vw;
}
