
.MuiTab-textColorPrimary.Mui-selected {
    color: rgb(227, 0, 11) !important;
    background-color: #e7e7e7 !important;
}

.MuiTab-root {
    min-width: 25% !important;
}

.css-1aquho2-MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(227, 0, 11) !important;
}