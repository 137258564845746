.separator{
    border-top: 1px solid rgb(216, 216, 216);
    margin-bottom: 10px;
}

.producto__text-code{
    color: rgb(190, 190, 190);
}

.producto__btn-qty{
    margin-right: 10px;
    width: 60px;
    Height: 2.125rem;
    display: inline-block;
    border-radius: 0 !important;
}

.producto__btn-add{
    background-color: #E86A25 !important;
    color: #fff !important;
    border-radius: 0 !important;
}

.producto__text-stock{
    color: #57c346;
    font-size: 0.8rem;
}

.producto__icon-stock{
    color: #57c346;
    width: 20px;
    margin-bottom: 10px;
}