.ShopRow_col{
}

.ShoppingCart__item-list{
    width: 100%;
    max-width: 100%;
    margin-right: 0px;
}

.ShoppingCart__items-container{
    min-width: 0;
}

.ShoppingCart__items-container {
    display: grid;
    grid-template-columns: 350px 150px 130px repeat(4, 80px);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-align: center;
    }

.ShoppingCart__items-1 { grid-area: 1 / 1 / 2 / 2; }
.ShoppingCart__items-2 { grid-area: 1 / 2 / 2 / 3; }
.ShoppingCart__items-3 { grid-area: 1 / 3 / 2 / 4; }
.ShoppingCart__items-4 { grid-area: 1 / 4 / 2 / 5; }
.ShoppingCart__items-5 { grid-area: 1 / 5 / 2 / 6; }
.ShoppingCart__items-6 { grid-area: 1 / 6 / 2 / 7; }
.ShoppingCart__items-7 { grid-area: 1 / 7 / 2 / 8; }

.ShopRow__product-inline{
    display: inline-block;
    vertical-align:middle;
}