.timeline {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .timeline-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #000000;
    color: #fff;
    border-radius: 50%;
    font-weight: bold;
    font-size: 14px;
    margin: 0 10px;
  }

  .timeline-item-white {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: #fff;
    color: #000000;
    border-radius: 50%;
    font-weight: bold;
    font-size: 14px;
    margin: 0 10px;
    border: 1px solid #000;
  }


  .line {
    height: 2px;
    width: 8%;
    background-color: black;
    display: flex;
    margin-top: 8px
  }
  